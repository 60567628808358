import React, { useRef, useState } from 'react';
import { Box, Grid, Typography, Button } from "@mui/material"
import {
    fetchDashboardList, fetchDashboardTree, fetchNodeConfig, fetchVibrationConfig,
    fetchNodeChannelConfig, updateNodeName, setChannelEnabled, setNodeConfig, updateNodeChannelName, setVibrationConfig,
    setNodeChannelConfig, updateFolderName, createFolder, deleteFolder, addObjectToFolder, fetchCompanyList,
    moveNodeToCompany, assignFolder, fetchCommands, queueCommands, deleteCommands, fetchSettingsEnums,
} from './SettingsQueryFunctions';
import { Toast } from 'primereact/toast';
import StatusMessage from "../Generic/StatusMessage";
import StatusBackdrop from "../Generic/StatusBackdrop";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import FolderCard from "../Generic/FolderCard";
import DiagnosticsModal from "../Generic/DiagnosticsModal"
import ConfigurationModal from "./ConfigurationModal";
import FolderModal from "./FolderModal";
import AssignNewCompanyModal from "./AssignNewCompanyModal";
import IssueCommandModal from "./IssueCommandModal";
import MassEditModal from "./MassEditModal";
import {
    setBasicBandFlags, setIntermediateAnalysisFlags, setIntermediateBandFlags,
    setLowFreqBandFlags, clearAnalysisFlags, clearBandFlags, convertToBandedCheckboxes,
    convertToMiscCheckboxes, convertFromBandedCheckboxes, convertFromMiscCheckboxes
} from "./VibrationFlagHelpers"
import {
    base64ToByteArray, base64ToNumber, numberToBase64, byteArrayToBase64,
    compareConfigs, sortConfigOptions, transformEnumsToObject
} from "./NodeSettingsHelpers";
import { createToastSuccess, createToastError } from "../Generic/MiscFunctions";
import ConfirmMessage from "../Generic/ConfirmMessage";
import { jwtDecode } from "jwt-decode";
import AuthService from '../Auth/AuthService';
import VibrationRedirectModal from "./VibrationRedirectModal";

// Page Styling
const pageStyle = {
    margin: "2%"
}

const TOAST_SUCCESS_LIFE = 3000;
const TOAST_ERROR_LIFE = 30000;

export default function NodeSettings() {

    const { companyID } = useParams();
    const toast = useRef(null);

    const token = jwtDecode(AuthService.getCurrentUser().token);
    const viewAll = (token.g_ge === "True" || token.g_ma === "True");
    const userID = token.nameid;

    //Members used to control the states of components
    const [errorState, setErrorState] = useState();
    const [selectedNode, setSelectedNode] = useState();
    const [selectedNodeConfig, setSelectedNodeConfig] = useState();
    const [editing, setEditing] = useState(false);
    const [enabling, setEnabling] = useState(false);
    const [assignObject, setAssignObject] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [folderModify, setFolderModify] = useState();
    const [commandModify, setCommandModify] = useState();

    ///* Vibration Analysis Enable Flag Handling *///
    const handleCheckboxClick = (row, index, checked) => {
        row.checkboxInfo[index].checked = checked;
        setEnableFlags({ ...enableFlags });
    }

    // Initialize enable flags
    const [enableFlags, setEnableFlags] = useState({
        x_amplitude: { rowTitle: "X Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_amplitude, index, event.target.checked); }},
        y_amplitude: { rowTitle: "Y Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_amplitude, index, event.target.checked); }},
        z_amplitude: { rowTitle: "Z Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_amplitude, index, event.target.checked); }},
        x_frequency: { rowTitle: "X Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_frequency, index, event.target.checked); }},
        y_frequency: { rowTitle: "Y Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_frequency, index, event.target.checked); }},
        z_frequency: { rowTitle: "Z Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_frequency, index, event.target.checked); }},
        x_analysis: { rowTitle: "X Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_analysis, index, event.target.checked); }},
        y_analysis: { rowTitle: "Y Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_analysis, index, event.target.checked); }},
        z_analysis: { rowTitle: "Z Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_analysis, index, event.target.checked); }},
    });

    //Data pulled from the API
    const [dashboardID, setDashboardID] = useState();
    const [dashRootNode, setDashRootNode] = useState();
    const [dashNodes, setDashNodes] = useState([]);
    const [folders, setFolders] = useState([]);
    const [parentFolderID, setParentFolderID] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    const [commands, setCommands] = useState([]);
    const [enums, setEnums] = useState([]);
    const [filteredDashboardIDs, setFilteredDashboardIDs] = useState([]);
    

    //Members used to determine if API requests are loading
    const [isLoadedDashboardTree, setIsLoadedDashboardTree] = useState(false);
    const [, setIsLoadedDashList] = useState(false);
    const [isLoadedCompanyList, setIsLoadedCompanyList] = useState(false);
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [loadNodeConfig, setLoadNodeConfig] = useState(false);
    const [loadVibrationConfig, setLoadVibrationConfig] = useState(false);
    const [loadNodeChannelConfig, setLoadNodeChannelConfig] = useState(false);
    const [loadCommands, setLoadCommands] = useState(false);
    const [loadSamplingRates, setLoadSamplingRates] = useState(false);

    //Controls whether certain modals or menus are open
    const [diagnosticsModalOpen, setDiagnosticsModalOpen] = useState(false);
    const [configModalOpen, setConfigModalOpen] = useState(false);
    const [folderModalOpen, setFolderModalOpen] = useState(false);
    const [vibrationRedirectModalOpen, setVibrationRedirectModalOpen] = useState(false);
    const [editConfig, setEditConfig] = useState(false);
    const [enableConfig, setEnableConfig] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [commandsModalOpen, setCommandsModalOpen] = useState(false);
    const [showFolderConfirm, setShowFolderConfirm] = useState(false);
    const [showCommandConfirm, setShowCommandConfirm] = useState(false);
    const [filters, setFilters] = useState([]);

    //Helper function that converts the dashboard tree from API into an array for display
    const convertTreeToArray = (tree, folderName) => {
        let arr = [];
        if (tree.ObjectType == enums.TreeObjectType['Folder']) {
            folderName = tree.DisplayName;
            if (!folders.some((value) => { return value.DisplayName == folderName }) && !folderName.includes("Axis")) {
                let newfolders = folders;
                newfolders.push(tree);
                setFolders(newfolders);
            }
        }
        if (tree.ObjectType == enums.TreeObjectType['Node']) {
            tree.FolderName = folderName;
            arr.push(tree);
        }
        for (let i = 0; i < tree.Children.length; i++) {
            if (tree.ObjectType == enums.TreeObjectType['Node']) {
                tree.Children[i].IsVBTx = tree.IsVBTx
            }
            arr = arr.concat(convertTreeToArray(tree.Children[i], folderName));
        }
        return arr;
    }

    /*
        Gets dashboards and trees using API calls
    */
    const { isLoading: dashListLoading } =
        useQuery(["dashlist", companyID], fetchDashboardList, {
            onSuccess: (dataT) => {
                setDashboardID(dataT[0].Item1);
                setIsLoadedDashList(true);
            },
            onError: (dashboardsError) => {
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    const { isLoading: dashTreeLoading } =
        useQuery({
            queryKey: ["dashtree", dashboardID],
            queryFn: fetchDashboardTree,
            onSuccess: (dataD) => {
                setDashRootNode(dataD);
                setParentFolderID(dataD.ObjectID);
                let dn = convertTreeToArray(dataD, "")
                setDashNodes(dn);
                if (filters.length > 0) {
                    setFilteredDashboardIDs(dn.filter((node) => node.VibrationObjectID == 0 && filters.includes(node.FolderName)).map(node => {
                        return node.ObjectID
                    }));
                } else {
                    setFilteredDashboardIDs(dn.filter((node) => node.VibrationObjectID == 0).map(node => {
                        return node.ObjectID
                    }));
                }
                setIsLoadedDashboardTree(true);
            },
            onError: (dashboardsError) => {
                setErrorState(dashboardsError || "An unexpected error occurred.");
            },
            enabled: !!dashboardID
        });

    const { isLoading: companiesLoading } =
        useQuery({
            queryKey: ["companies"],
            queryFn: fetchCompanyList,
            onSuccess: (dataC) => {
                setCompanyList(dataC);
                setSelectedCompany(dataC[0].CompanyID);
                setIsLoadedCompanyList(true);
            },
            onError: (companiesError) => {
                setErrorState(companiesError || "An unexpected error occurred.");
            }
        });

    const { isLoading: enumsLoading } =
        useQuery(["vibrationEnums"], fetchSettingsEnums, {
            onSuccess: (dataE) => {
                setEnums(transformEnumsToObject(dataE));
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

        useQuery({
            queryKey: ["nodeconfig", selectedNode?.ObjectID, companyID],
            queryFn: fetchNodeConfig,
            onSuccess: (result) => {
                setLoadNodeConfig(false);
                let configs = result.map(parseConfigOption);
                configs.sort(compareConfigs);
                setSelectedNodeConfig(configs);
                setConfigModalOpen(true);
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to get node configuration', TOAST_ERROR_LIFE));
            },
            enabled: loadNodeConfig
        });

        useQuery({
            queryKey: ["vibrationconfig", selectedNode?.ObjectID, companyID],
            queryFn: fetchVibrationConfig,
            onSuccess: (result) => {
                setLoadVibrationConfig(false);
                let configs = result.map(parseConfigOption);
                configs.sort(compareConfigs);
                setSelectedNodeConfig(configs);
                setConfigModalOpen(true);
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to get vibration configuration', TOAST_ERROR_LIFE));
            },
            enabled: loadVibrationConfig
        });

        useQuery({
            queryKey: ["nodechannelconfig", selectedNode?.ObjectID, companyID],
            queryFn: fetchNodeChannelConfig,
            onSuccess: (result) => {
                setLoadNodeChannelConfig(false);
                let configs = result.map(parseConfigOption);
                configs.sort(compareConfigs);
                setSelectedNodeConfig(configs);
                setConfigModalOpen(true);
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to get node configuration', TOAST_ERROR_LIFE));
            },
            enabled: loadNodeChannelConfig
        });

        useQuery({
            queryKey: ["commands", selectedNode?.ObjectID],
            queryFn: fetchCommands,
            onSuccess: (result) => {
                setLoadCommands(false);
                setCommands(result);
                setCommandsModalOpen(true);
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while get commands', TOAST_ERROR_LIFE));
            },
            enabled: loadCommands
        });

    const doUpdateNodeName =
        useMutation(({ dashboardObjectID, displayName }) => updateNodeName(dashboardObjectID, displayName, companyID), {
            onSuccess: (_) => {
                toast.current.show(createToastSuccess('Node name updated', TOAST_SUCCESS_LIFE));
                let newNode = dashNodes.find((node) => node.ObjectID == selectedNode.ObjectID);
                newNode.DisplayName = selectedNode.DisplayName;
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to update node name', TOAST_ERROR_LIFE));
            }
        });

    const doUpdateNodeChannelName =
        useMutation(({ dashboardObjectID, displayName }) => updateNodeChannelName(dashboardObjectID, displayName, companyID), {
            onSuccess: (_) => {
                let newParentNode = dashNodes.find((node) => node.ObjectID == selectedNode.ParentObjectID);
                let newChannel = newParentNode.Children.find((node) => node.ObjectID == selectedNode.ObjectID);
                newChannel.DisplayName = selectedNode.DisplayName
                toast.current.show(createToastSuccess('Node channel name updated', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to update node name', TOAST_ERROR_LIFE));
            }
        });

    const doUpdateFolderName =
        useMutation(({ dashboardObjectID, displayName }) => updateFolderName(dashboardObjectID, displayName, companyID), {
            onSuccess: (_) => {
                toast.current.show(createToastSuccess('Folder name updated', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to update folder name', TOAST_ERROR_LIFE));
            }
        });

    const doSetChannelEnabled =
        useMutation(({ dashboardObjectID, isEnabled }) => setChannelEnabled(dashboardObjectID, isEnabled, companyID), {
            onSuccess: (_) => {
                let newParentNode = dashNodes.find((node) => node.ObjectID == selectedNode.ParentObjectID);
                let newChannel = newParentNode.Children.find((node) => node.ObjectID == selectedNode.ObjectID);
                newChannel.IsEnabled = selectedNode.IsEnabled
                toast.current.show(createToastSuccess('Node channel enable set', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to set node channel enable', TOAST_ERROR_LIFE));
            }
        });

    const doSetNodeConfig =
        useMutation(({ dashboardObjectID, configs }) => setNodeConfig(dashboardObjectID, companyID, configs), {
            onSuccess: (_) => {
                handleConfigModalClose();
                toast.current.show(createToastSuccess('Node configuration updated', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to update node configuration', TOAST_ERROR_LIFE));
            }
        });

    const doSetVibrationConfig =
        useMutation(({ dashboardObjectID, configs }) => setVibrationConfig(dashboardObjectID, companyID, configs), {
            onSuccess: (_) => {
                handleConfigModalClose();
                setVBTxConfigModalOpen(false);
                toast.current.show(createToastSuccess('Vibration configuration updated', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to update vibration configuration', TOAST_ERROR_LIFE));
            }
        });

    const doSetNodeChannelConfig =
        useMutation(({ dashboardObjectID, configs }) => setNodeChannelConfig(dashboardObjectID, companyID, configs), {
            onSuccess: (_) => {
                handleConfigModalClose();
                handleDiagnosticModalClose();
                toast.current.show(createToastSuccess('Node channel configuration updated', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to udpate node channel configuration', TOAST_ERROR_LIFE));
            }
        });

    const doCreateFolder =
        useMutation(({ parentDashboardObjectID, displayName }) => createFolder(parentDashboardObjectID, displayName, companyID), {
            onSuccess: (_) => {
                setFolderModalOpen(false);
                toast.current.show(createToastSuccess('Folder created', TOAST_SUCCESS_LIFE));
                setTimeout(() => { window.location.reload() }, TOAST_SUCCESS_LIFE); // Match the life of the toast
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to create folder', TOAST_ERROR_LIFE));
            }
        });

    const doDeleteFolder =
        useMutation((dashboardID) => deleteFolder(dashboardID, companyID), {
            onSuccess: (_) => {
                setFolders(folders.filter((folder) => folder.ObjectID != folderModify));
                setFolderModify(-1);
                setFolderModalOpen(true);
                toast.current.show(createToastSuccess('Folder deleted', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to delete folder', TOAST_ERROR_LIFE));
            }
        });

    const doAddObjectToFolder =
        useMutation(({ dashboardObjectID, newParentObjectID }) => addObjectToFolder(dashboardObjectID, newParentObjectID, companyID), {
            onSuccess: (_) => {
                let newFolder = folders.find((node) => node.ObjectID == folderModify);
                let newNode = dashNodes.find((node) => node.ObjectID == selectedNode.ObjectID);
                newNode.FolderName = newFolder.DisplayName;
                newNode.ParentObjectID = newFolder.ObjectID;
                toast.current.show(createToastSuccess('Object assigned to folder', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to assign object to folder', TOAST_ERROR_LIFE));
            }
        });

    const doMoveNodeToCompany =
        useMutation(({ dashboardObjectID, newCompanyID }) => moveNodeToCompany(dashboardObjectID, newCompanyID, companyID), {
            onSuccess: (_) => {
                setAssignModalOpen(false);
                toast.current.show(createToastSuccess('Node moved to company', TOAST_SUCCESS_LIFE));
                setTimeout(() => { window.location.reload() }, TOAST_SUCCESS_LIFE); // Match the life of the toast
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to move node to company', TOAST_ERROR_LIFE));
            }
        });

    const doAssignFolder =
        useMutation(({ dashboardObjectID, newCompanyID }) => assignFolder(dashboardObjectID, newCompanyID, companyID), {
            onSuccess: (_) => {
                setAssignModalOpen(false);
                toast.current.show(createToastSuccess('Folder assigned to company', TOAST_SUCCESS_LIFE));
                setTimeout(() => { window.location.reload() }, TOAST_SUCCESS_LIFE); // Match the life of the toast
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to assign folder to company', TOAST_ERROR_LIFE));
            }
        });

    const doQueueCommand =
        useMutation(({ dashboardObjectID, commandTypeID }) => queueCommands(dashboardObjectID, commandTypeID), {
            onSuccess: (_) => {
                setCommandsModalOpen(false);
                toast.current.show(createToastSuccess('Command Queued', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to queue command', TOAST_ERROR_LIFE));
            }
        });

    const doDeleteCommand =
        useMutation(({ dashboardObjectID, commandTypeID }) => deleteCommands(dashboardObjectID, commandTypeID), {
            onSuccess: (_) => {
                setCommandsModalOpen(false);
                let newCommand = commands.find((command) => command.CommandTypeID == commandModify);
                newCommand.QueuedOn = null;
                setCommandModify(-1);
                toast.current.show(createToastSuccess('Command deleted', TOAST_SUCCESS_LIFE));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to delete command', TOAST_ERROR_LIFE));
            }
        });

    //Converts configs from API into a new object for display
    const parseConfigOption = (config) => {
        let value = base64ToNumber(config.OptionBytes);
        //VBT1/2 Enabled Vibration Channels should be handled differently
        if (config.ConfigurationOptionID == enums.ConfigurationOption['Enabled V&T Channels']) {
            value.value = base64ToByteArray(config.OptionBytes);
            value.value = convertEnableFlagsFromAPI(value.value);
        }
        let options = JSON.parse(config.OptionJson);
        if (config.ConfigurationOptionID == enums.ConfigurationOption['Rotations Per Second - Lower Bound'] ||
            config.ConfigurationOptionID == enums.ConfigurationOption['Rotations Per Second - Upper Bound']) {
            options.options.min = options.options.min * 60;
            options.options.max = options.options.max * 60;
            value.value = value.value * 60;
            }
        return {
            configurationOptionID: config.ConfigurationOptionID,
            optionValue: value.value,
            optionTypeID: config.OptionTypeID,
            options: options.options,
            defaultValue: options['default value'],
            displayName: config.DisplayName,
            length: value.length,
            sort: sortConfigOptions(enums, config.ConfigurationOptionID)
        }
    }

    /*
        Renders the buttons on the right side of the rows
    */
    const renderButtonAction = (node) => {
        switch (node.ObjectType) {
            case enums.TreeObjectType['Diagnostics']:
                return (<Button
                    style={{ float: "right" }}
                    onClick={() => { handleDiagnosticsClick(node) }}
                >
                    Details
                </Button>)
            case enums.TreeObjectType['Sensor Data']:
                return (<Button
                    style={{ float: "right" }}
                    onClick={() => { handleConfigClick(node) }}
                >
                    {node.IsVBTx ? 'Rename' : 'Configure'}
                </Button>)
            default:
                return (<Button
                    style={{ float: "right" }}
                    onClick={() => {handleConfigClick(node)}}
                >
                    Configure
                </Button>)
        }
        
    }

    /*
        Handlers for the Diagnostics modal
    */
    const handleDiagnosticsClick = (node) => {
        setSelectedNode(node);
        setDiagnosticsModalOpen(true);
    }

    const handleDiagnosticModalClose = () => { setDiagnosticsModalOpen(false); }

    const handleDiagnosticNameChange = (event, id) => {
        let children = selectedNode.Children;
        children.find((diag) => diag.ObjectID == id).DisplayName = event.target.value
        setSelectedNode(prevNode => ({
            ...prevNode,
            Children: children
        }));
    }

    const handleDiagnosticNameSave = (id) => {
        let children = selectedNode.Children;
        let newName = children.find((diag) => diag.ObjectID == id).DisplayName
        doUpdateNodeChannelName.mutateAsync({
            dashboardObjectID: id,
            displayName: newName
        })
    }

    const handleDiagnosticCheckboxChange = (event, id) => {
        let children = selectedNode.Children;
        let node = children.find((diag) => diag.ObjectID == id)
        node.IsEnabled = event.target.checked;
        doSetChannelEnabled.mutateAsync({
            dashboardObjectID: id,
            isEnabled: event.target.checked
        })
    }

    /*
        Handlers for the Configuration modal
    */
    const handleConfigClick = (node) => {
        setSelectedNode(node);
        switch (node.ObjectType) {
            case enums.TreeObjectType['Node']:
                setLoadNodeConfig(true);
                setEditConfig(true);
                setEnableConfig(false);
                break;
            case enums.TreeObjectType['Vibration']:
                if (node.VibrationObjectID != 0 || node.IsVBTx) {
                    setVibrationRedirectModalOpen(true);
                } else {
                    setLoadVibrationConfig(true);
                    setEditConfig(false);
                    setEnableConfig(false);
                }
                break;
            case enums.TreeObjectType['Sensor Data']:
                setLoadNodeChannelConfig(true);
                setEnableConfig(true);
                setEditConfig(true);
                break;
        }
        
    }

    const handleConfigModalClose = (_) => {
        setConfigModalOpen(false);
        setEditing(false);
        setEnabling(false);
    }

    const clearAllBandFlags = () => {
        enableFlags.x_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.y_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.z_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.x_frequency.checkboxInfo = clearBandFlags();
        enableFlags.y_frequency.checkboxInfo = clearBandFlags();
        enableFlags.z_frequency.checkboxInfo = clearBandFlags();
        enableFlags.x_analysis.checkboxInfo = clearAnalysisFlags();
        enableFlags.y_analysis.checkboxInfo = clearAnalysisFlags();
        enableFlags.z_analysis.checkboxInfo = clearAnalysisFlags();
    }

    const convertEnableFlagsFromAPI = (enabledVibrationChannels) => {
        const int16 = (enabledVibrationChannels[6] << 8) | enabledVibrationChannels[7];
        let miscCheckboxes = convertToMiscCheckboxes(int16);
        enableFlags.x_analysis.checkboxInfo = miscCheckboxes.x
        enableFlags.y_analysis.checkboxInfo = miscCheckboxes.y
        enableFlags.z_analysis.checkboxInfo = miscCheckboxes.z
        enableFlags.x_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[0])
        enableFlags.y_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[1])
        enableFlags.z_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[2])
        enableFlags.x_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[3])
        enableFlags.y_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[4])
        enableFlags.z_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels[5])
        setEnableFlags(enableFlags);
        return enableFlags
    }

    const convertEnableFlagsForAPI = (flags) => {
        let EnabledVibrationFlags = [];
        EnabledVibrationFlags[0] = convertFromBandedCheckboxes(flags.x_amplitude.checkboxInfo);
        EnabledVibrationFlags[1] = convertFromBandedCheckboxes(flags.y_amplitude.checkboxInfo);
        EnabledVibrationFlags[2] = convertFromBandedCheckboxes(flags.z_amplitude.checkboxInfo);
        EnabledVibrationFlags[3] = convertFromBandedCheckboxes(flags.x_frequency.checkboxInfo);
        EnabledVibrationFlags[4] = convertFromBandedCheckboxes(flags.y_frequency.checkboxInfo);
        EnabledVibrationFlags[5] = convertFromBandedCheckboxes(flags.z_frequency.checkboxInfo);
        let int16 = convertFromMiscCheckboxes(enableFlags.x_analysis.checkboxInfo, enableFlags.y_analysis.checkboxInfo, enableFlags.z_analysis.checkboxInfo)
        EnabledVibrationFlags[6] = (int16 >> 8) & 0xF
        EnabledVibrationFlags[7] = int16 & 0xF
        return EnabledVibrationFlags;
    }

    // Handle Changes in Analysis Type
    const handleAnalysisTypeChange = (event) => {

        // Set Band Enable Flags
        switch (event.target.value) {
            case enums.AnalysisType['Basic Fixed Speed']:
            case enums.AnalysisType['Basic Variable Speed']:
            case enums.AnalysisType['Temperature Focused Fixed Speed']:
            case enums.AnalysisType['Temperature Focused Variable Speed']:
                clearAllBandFlags();
                enableFlags.x_amplitude.checkboxInfo = setBasicBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setBasicBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setBasicBandFlags();
                break;

            case enums.AnalysisType['Low Frequency Fixed Speed']:
            case enums.AnalysisType['Low Frequency Variable Speed']:
                clearAllBandFlags();
                enableFlags.x_amplitude.checkboxInfo = setLowFreqBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setLowFreqBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setLowFreqBandFlags();
                break;

            case enums.AnalysisType['Intermediate VBT']:
                enableFlags.x_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.x_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                enableFlags.y_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                enableFlags.z_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                break;

            case enums.AnalysisType['Advanced VBT']:
                break;
        }
        setEnableFlags({ ...enableFlags });
    }

    const handleConfigChange = (event, id) => {
        let configOption = selectedNodeConfig.find((config) => config.configurationOptionID == id);
        configOption.optionValue = event.target.value;
        if (configOption.optionTypeID == enums.ConfigurationOptionType['Boolean']) {
            
            if (event.target.checked) {
                configOption.optionValue = 1;
            }
            else {
                configOption.optionValue = 0;
            }
        }
        //Update other fields if analysis type changes
        if (id == enums.ConfigurationOption['Analysis Type']) {
            if (configOption.optionValue == enums.AnalysisType['Basic Fixed Speed'] || configOption.optionValue == enums.AnalysisType['Low Frequency Fixed Speed'] ||
                configOption.optionValue == enums.AnalysisType['Temperature Focused Fixed Speed']) {
                let configOption = selectedNodeConfig.find((config) => config.configurationOptionID == enums.ConfigurationOption['Drive Type']);
                configOption.optionValue = 0;
                setSelectedNodeConfig(prevList => prevList.map(item =>
                    item.configurationOptionID === enums.ConfigurationOption['Drive Type'] ? configOption : item
                ));
            }
            else if (configOption.optionValue == enums.AnalysisType['Basic Variable Speed'] || configOption.optionValue == enums.AnalysisType['Low Frequency Variable Speed'] ||
                configOption.optionValue == enums.AnalysisType['Temperature Focused Variable Speed']) {
                let configOption = selectedNodeConfig.find((config) => config.configurationOptionID == enums.ConfigurationOption['Drive Type']);
                configOption.optionValue = 1;
                setSelectedNodeConfig(prevList => prevList.map(item =>
                    item.configurationOptionID === enums.ConfigurationOption['Drive Type'] ? configOption : item
                ));
            }
            handleAnalysisTypeChange(event);
        }
        
        setSelectedNodeConfig(prevList => prevList.map(item =>
            item.configurationOptionID === id ? configOption : item
        ));
    }

    const handleConfigSaveButtonClick = () => {
        let error = false;
        selectedNodeConfig.forEach((config) => {
            if (config.optionTypeID == enums.ConfigurationOptionType['Freeform'] && config.configurationOptionID != enums.ConfigurationOption['Enabled V&T Channels']) {
                error = error || config.optionValue < config.options.min || config.optionValue > config.options.max
            }
        });
        const lowerBound = selectedNodeConfig.find(config => config.configurationOptionID == enums.ConfigurationOption['Rotations Per Second - Lower Bound']);
        const upperBound = selectedNodeConfig.find(config => config.configurationOptionID == enums.ConfigurationOption['Rotations Per Second - Upper Bound']);
        if (error || lowerBound?.optionValue > upperBound?.optionValue - 240) {
            return;
        }
        let newConfigs = selectedNodeConfig.map((config) => {
            if (config.configurationOptionID == enums.ConfigurationOption['Enabled V&T Channels']) {
                return ({
                    ConfigurationOptionID: config.configurationOptionID,
                    OptionByteString: byteArrayToBase64(convertEnableFlagsForAPI(enableFlags))

                })
            } else if (config.configurationOptionID == enums.ConfigurationOption['Rotations Per Second - Lower Bound'] ||
                config.configurationOptionID == enums.ConfigurationOption['Rotations Per Second - Upper Bound']) {
                return ({
                    ConfigurationOptionID: config.configurationOptionID,
                    OptionByteString: numberToBase64(config.optionValue / 60, config.length)

                })
            } else {
                return ({
                    ConfigurationOptionID: config.configurationOptionID,
                    OptionByteString: numberToBase64(config.optionValue, config.length)

                })
            }
        });
        //Switch API calls based on what object is being viewed.
        switch (selectedNode.ObjectType) {
            case enums.TreeObjectType['Node']:
                doSetNodeConfig.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, configs: newConfigs });
                break;
            case enums.TreeObjectType['Vibration']:
                doSetVibrationConfig.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, configs: newConfigs });
                break;
            case enums.TreeObjectType['Sensor Data']:
                doSetNodeChannelConfig.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, configs: newConfigs });
                break;
        }
        if (editing) {
            if (selectedNode.ObjectType == enums.TreeObjectType['Node']) {
                doUpdateNodeName.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, displayName: selectedNode.DisplayName });
            } else if (selectedNode.ObjectType == enums.TreeObjectType['Sensor Data']) {
                doUpdateNodeChannelName.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, displayName: selectedNode.DisplayName });
            }

        }
        if (enabling) {
            doSetChannelEnabled.mutateAsync({ dashboardObjectID: selectedNode.ObjectID, isEnabled: selectedNode.IsEnabled });
        }
        if (selectedNode.ObjectType == enums.TreeObjectType['Node']) {
            let folderName = folders.find((node) => node.ObjectID == folderModify)?.DisplayName
            if (folderName && selectedNode.FolderName != folderName) {
                doAddObjectToFolder.mutateAsync({
                    dashboardObjectID: selectedNode.ObjectID,
                    newParentObjectID: folderModify
                })
            }
        }
        
        
    };


    const handleSelectedNodeEnable = () => {
        setEnabling(true);
        setSelectedNode(prevNode => ({
            ...prevNode,
            IsEnabled: event.target.checked
        }));
    }

    const handleEditButtonClick = () => { setEditing(true); }

    const handleSelectedNodeNameUpdate = () => {
        setSelectedNode(prevNode => ({
            ...prevNode,
            DisplayName: event.target.value
        }));
    }

    /*
        Handlers for the Folder Management
    */
    const handleFolderModalOpen = () => { setFolderModalOpen(true); }

    const handleFolderModalClose = () => { setFolderModalOpen(false); }

    const handleCreateFolderClick = (newFolderName) => {
        doCreateFolder.mutateAsync({
            parentDashboardObjectID: parentFolderID,
            displayName: newFolderName
        });
    }

    const handleFolderNameChange = (event, id) => {
        let newFolder = folders.find((fol) => fol.ObjectID == id)
        newFolder.DisplayName = event.target.value
        setFolders(prevList => prevList.map(item =>
            item.OptionID === id ? newFolder : item
        ));
    }

    const handleDeleteFolderClick = (id) => {
        setFolderModify(id);
        setFolderModalOpen(false);
        setShowFolderConfirm(true);
    }

    const handleFolderConfirm = () => {
        doDeleteFolder.mutateAsync(folderModify);
        setShowFolderConfirm(false);
    }

    const handleFolderReject = () => {
        setFolderModalOpen(true);
        setShowFolderConfirm(false);
    }

    const handleFolderNameSave = (id) => {
        let newFolderName = folders.find((fol) => fol.ObjectID == id).DisplayName;
        doUpdateFolderName.mutateAsync({
            dashboardObjectID: id,
            displayName: newFolderName
        })
    }

    const handleFolderChange = (event, _) => {
        setFolderModify(event.target.value);
        setSelectedNode(prevNode => ({
            ...prevNode,
            ParentObjectID: event.target.value
        }));
    }

    /*
        Handlers for the New Company Assignment
    */
    const handleAssignModalOpen = (object) => {
        setFolderModalOpen(false);
        setConfigModalOpen(false);
        setAssignModalOpen(true);
        setAssignObject(object);

    }

    const handleAssignModalClose = () => {
        setAssignModalOpen(false);
        setAssignObject();
    }

    const handleAssignment = () => {
        if (assignObject.ObjectType == enums.TreeObjectType['Folder']) {
            doAssignFolder.mutateAsync({
                dashboardObjectID: assignObject.ObjectID,
                newCompanyID: selectedCompany
            })
        }
        else if (assignObject.ObjectType == enums.TreeObjectType['Node']) {
            doMoveNodeToCompany.mutateAsync({
                dashboardObjectID: assignObject.ObjectID,
                newCompanyID: selectedCompany
            })
        }
    }

    const handleSelectedCompanyChange = (event) => { setSelectedCompany(event.target.value); }

    /*
        Handlers for the Issue Commands modal
    */
    const handleIssueCommandClick = (_) => { setLoadCommands(true); }

    const handleIssueCommandClose = () => { setCommandsModalOpen(false); }

    const handleQueueCommand = (commandID) => {
        doQueueCommand.mutateAsync({
            dashboardObjectID: selectedNode.ObjectID,
            commandTypeID: commandID
        })
    }

    const handleDeleteCommand = (commandID) => {
        setCommandsModalOpen(false);
        setConfigModalOpen(false);
        setShowCommandConfirm(true);
        setCommandModify(commandID);
    }

    const handleCommandConfirm = () => {
        setShowCommandConfirm(false);
        doDeleteCommand.mutateAsync({
            dashboardObjectID: selectedNode.ObjectID,
            commandTypeID: commandModify
        })
    }

    const handleCommandReject = () => {
        setShowCommandConfirm(false);
        setConfigModalOpen(true);
        setCommandsModalOpen(true);
    }

    const renderConfirmMessage = () => {
        if (showFolderConfirm) {
            return (<ConfirmMessage
                message={{
                    message: "Are you sure you want to delete this folder?",
                    header: "Confirmation"
                }}
                acceptFunction={handleFolderConfirm}
                rejectFunction={handleFolderReject}
                showConfirmDialog={showFolderConfirm}
            />)
        } else if (showCommandConfirm) {
            return (<ConfirmMessage
                message={{
                    message: "Are you sure you want to cancel this command?",
                    header: "Confirmation"
                }}
                acceptFunction={handleCommandConfirm}
                rejectFunction={handleCommandReject}
                showConfirmDialog={showCommandConfirm}
            />)
        }

    }

    const handleMassEditOpen = () => {
        setLoadSamplingRates(true);
    }

    const onFilterChange = (_changedColumn, filterList, _type, changedColumnIndex) => {
        let newFilters = filterList[changedColumnIndex];
        setFilters(newFilters)
        if (newFilters.length < 1) {
            setFilteredDashboardIDs(dashNodes.filter((node) => node.VibrationObjectID == 0).map(node => {
                    return node.ObjectID
                }));
        } else {
            setFilteredDashboardIDs(dashNodes.filter((node) => node.VibrationObjectID == 0 && newFilters.includes(node.FolderName)).map(node => {
                    return node.ObjectID
                }));
        }
    }

    return (
        <Box sx={pageStyle}>
            {(dashTreeLoading || dashListLoading || companiesLoading || enumsLoading || loadSamplingRates) &&
                <StatusBackdrop open={(dashTreeLoading || dashListLoading || companiesLoading || enumsLoading || loadSamplingRates)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="warning"
                    location="Settings"
                    statusCode={errorState?.request?.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">Node Settings</Typography>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        style={{ float: 'right' }}
                        onClick={handleMassEditOpen}
                    >
                        Mass Edit Nodes
                    </Button>

                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        style={{ float: 'right' }}
                        onClick={handleFolderModalOpen}
                    >
                        Manage Folders
                    </Button>

                </Grid>
                {isLoadedDashboardTree && isLoadedEnums && <Grid item xs={12}>
                    <FolderCard
                        node={dashRootNode}
                        list={dashNodes}
                        renderChildAction={renderButtonAction}
                        showChildren={true}
                        renderParentAction={renderButtonAction}
                        showFilter={true}
                        onFilterChange={onFilterChange}
                    />
                </Grid>}
            </Grid>
            <DiagnosticsModal
                node={selectedNode}
                diagnosticsModalOpen={diagnosticsModalOpen}
                handleDiagnosticModalClose={handleDiagnosticModalClose}
                handleNameChange={handleDiagnosticNameChange}
                handleCheckboxChange={handleDiagnosticCheckboxChange}
                handleNameSave={handleDiagnosticNameSave}
            />
            {isLoadedEnums && < ConfigurationModal
                configModalOpen={configModalOpen}
                configs={selectedNodeConfig}
                node={selectedNode}
                enums={enums}
                handleConfigModalClose={handleConfigModalClose}
                handleConfigChange={handleConfigChange}
                edit={editConfig}
                enable={enableConfig}
                handleConfigSaveButtonClick={handleConfigSaveButtonClick}
                handleSelectedNodeEnable={handleSelectedNodeEnable}
                handleEditButtonClick={handleEditButtonClick}
                handleSelectedNodeNameUpdate={handleSelectedNodeNameUpdate}
                editing={editing}
                enabling={enabling}
                folders={folders}
                handleFolderChange={handleFolderChange}
                viewAll={viewAll}
                handleAssignModalOpen={handleAssignModalOpen}
                handleIssueCommandClick={handleIssueCommandClick}
            />}
           <FolderModal
                folders={folders}
                folderModalOpen={folderModalOpen}
                handleFolderModalClose={handleFolderModalClose}
                handleCreateFolderClick={handleCreateFolderClick}
                handleFolderNameChange={handleFolderNameChange}
                handleFolderNameSave={handleFolderNameSave}
                handleDeleteFolderClick={handleDeleteFolderClick}
                viewAll={viewAll}
                handleAssignModalOpen={handleAssignModalOpen}
            />
            {isLoadedCompanyList && <AssignNewCompanyModal
                assignModalOpen={assignModalOpen}
                companyList={companyList}
                assignObject={assignObject}
                handleAssignModalClose={handleAssignModalClose}
                handleAssignment={handleAssignment}
                selectedCompany={selectedCompany}
                handleSelectedCompanyChange={handleSelectedCompanyChange}
            />}
            <IssueCommandModal
                commandsModalOpen={commandsModalOpen}
                commands={commands}
                handleIssueCommandClose={handleIssueCommandClose}
                handleQueueCommand={handleQueueCommand}
                handleDeleteCommand={handleDeleteCommand}
            />
            <MassEditModal
                dashNodes={dashNodes}
                toast={toast}
                loadSamplingRates={loadSamplingRates}
                setLoadSamplingRates={setLoadSamplingRates}
                filteredDashboardIDs={filteredDashboardIDs}
            />
            <VibrationRedirectModal
                open={vibrationRedirectModalOpen}
                setOpen={setVibrationRedirectModalOpen}
                userID={userID}
                companyID={companyID}
                vibrationObjectID={selectedNode?.VibrationObjectID}
                viewAll={viewAll}
            />
            {renderConfirmMessage()}
        </Box>
    )
}