import React from 'react';
import { Grid, TextField, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Item = styled(Grid, TextField)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

export default function AlarmThresholdField(props) {
    return (
        <Stack sx={{ width: '100%' }}>
            <Item>
                <NotificationsIcon sx={{ float: 'left', color: props.color }} />
                <Typography paddingLeft={1}>{props.name}</Typography>
            </Item>
            <Item>
                <TextField
                    required
                    label="Threshold"
                    inputProps={{ type: 'number', step: 0.001, min: 0, max: 2 }}
                    value={props.value}
                    helperText="Must be between 0 and 2"
                    error={props.error}
                    onChange={event => props.onChange(event, props.thresholdID, props.positionIdx)}
                    id={props.thresholdID}
                    name={props.thresholdID}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />
            </Item>
        </Stack>
    )
}