import { VBTxConfigurationInitialValues } from "../Models/VBTxConfigurationInitialValues"
import { numberToBase64 } from "../../NodeSettings/NodeSettingsHelpers"

//Maps an enum from the database into a format digestible by formik
export const mapEnumToFormik = (type) => {
    return ({
        "value": type.ID,
        "label": type.DisplayName
    });
}

//Select the appropriate defect level based on analysis type
const mapAnalysisType = (analysisType) => {
    return analysisType;
}

//Return an node with its setting configured
const assignedNodeInit = (node) => {
    return {
        "nodeID": node ? node.NodeID : 0,
        "axisAlignment": node ? node.AxisAlignment : 0,
        "shaftSpeedSource": node ? node.ShaftSpeedSource : 1,
        "xEnabled": node ? node.XEnabled : true,
        "yEnabled": node ? node.YEnabled : true,
        "zEnabled": node ? node.ZEnabled : true,
        "warningThreshold": node ? node.WarningThreshold : 0.1,
        "dangerThreshold": node ? node.DangerThreshold : 0.3,
        "parentGateway": node ? node.ParentGateway : 0,
    }
}

const defaultNodeConfig = {
    "nodeID": 0,
    "axisAlignment": 0,
    "shaftSpeedSource": 1,
    "xEnabled": true,
    "yEnabled": true,
    "zEnabled": true,
    "warningThreshold": 0.1,
    "dangerThreshold": 0.3,
    "parentGateway": 0
}

//Get the initial values for the form from the incoming node to edit or defaults if adding
export const getInitialValues = (vbtxAsset, editing) => {
    if (vbtxAsset) {
        return {
            "assetName": vbtxAsset.AssetName,
            "machineType": vbtxAsset.MachineType,
            "driveType": vbtxAsset.DriveType,
            //Don't load in the nodes in the clone asset case
            "p1AssignedNode": editing ? assignedNodeInit(vbtxAsset.p1AssignedNode) : defaultNodeConfig,
            "p2AssignedNode": editing ? assignedNodeInit(vbtxAsset.p2AssignedNode) : defaultNodeConfig,
            "p3AssignedNode": editing ? assignedNodeInit(vbtxAsset.p3AssignedNode) : defaultNodeConfig,
            "p4AssignedNode": editing ? assignedNodeInit(vbtxAsset.p4AssignedNode) : defaultNodeConfig,
            "gearboxLocation": vbtxAsset.GearboxLocation,
            "bladeCount": vbtxAsset.BladeCount,
            "inputToothCount": vbtxAsset.InputToothCount,
            "outputToothCount": vbtxAsset.OutputToothCount,
            "shaftSpeed": vbtxAsset.ShaftSpeed,
            "shaftSpeedLoBound": vbtxAsset.ShaftSpeedLoBound,
            "shaftSpeedHiBound": vbtxAsset.ShaftSpeedHiBound,
            "vibrationSamplingPeriod": vbtxAsset.VibrationSamplingPeriod,
            "temperatureSamplingPeriod": vbtxAsset.TemperatureSamplingPeriod,
            "triggerSource": vbtxAsset.TriggerSource,
            "threshold": vbtxAsset.Threshold,
            "maxTriggers": vbtxAsset.MaxTriggers,
            "defectLevel": vbtxAsset.DefectLevel,
            "maximizeNodes": vbtxAsset.MaximizeNodes,
            "disableShaftSpeedSource": vbtxAsset.DriveType == 0,
            "sameGateway": vbtxAsset.SameGateway,
            "parentGateway": vbtxAsset.ParentGateway,
            "warningRecipientIDs": vbtxAsset.WarningRecipientIDs,
            "dangerRecipientIDs": vbtxAsset.DangerRecipientIDs,
        }
    }
    else {
        return VBTxConfigurationInitialValues
    }
}

//Convert formik values for node to object for API to handle
const getVibrationObjectNode = (node) => {
    return ({
        NodeID: Number(node.nodeID),
        AxisAlignment: Number(node.axisAlignment),
        ShaftSpeedSource: Number(node.shaftSpeedSource),
        ParentGateway: Number(node.parentGateway),
        XEnabled: node.xEnabled,
        YEnabled: node.yEnabled,
        ZEnabled: node.zEnabled,
        WarningThreshold: node.warningThreshold || 0,
        DangerThreshold: node.dangerThreshold || 0,
    })
}

//Convert the defect level into an analysis type
const getAnalysisType = (formikValues) => {
    return formikValues.defectLevel;
}

//Create post object to send to API
export const createPostObject = (formikValues, editing, userID, companyID, objectID) => {
    const asset = {
        CompanyID: Number(companyID),
        UserID: Number(userID),
        ObjectID: editing ? Number(objectID) : null,
        AssetName: formikValues.assetName,
        MachineType: Number(formikValues.machineType),
        DriveType: Number(formikValues.driveType),
        p1AssignedNode: getVibrationObjectNode(formikValues.p1AssignedNode),
        p2AssignedNode: getVibrationObjectNode(formikValues.p2AssignedNode),
        p3AssignedNode: getVibrationObjectNode(formikValues.p3AssignedNode),
        p4AssignedNode: getVibrationObjectNode(formikValues.p4AssignedNode),
        GearboxLocation: formikValues.gearboxLocation,
        BladeCount: formikValues.bladeCount,
        InputToothCount: formikValues.inputToothCount,
        OutputToothCount: formikValues.outputToothCount,
        ShaftSpeed: formikValues.shaftSpeed,
        ShaftSpeedLoBound: formikValues.shaftSpeedLoBound,
        ShaftSpeedHiBound: formikValues.shaftSpeedHiBound,
        VibrationSamplingPeriod: formikValues.vibrationSamplingPeriod,
        TemperatureSamplingPeriod: formikValues.temperatureSamplingPeriod,
        TriggerSource: formikValues.triggerSource,
        Threshold: formikValues.threshold,
        MaxTriggers: formikValues.maxTriggers,
        DefectLevel: formikValues.defectLevel,
        MaximizeNodes: formikValues.maximizeNodes,
        SameGateway: formikValues.sameGateway,
        ParentGateway: formikValues.parentGateway,
        "warningRecipientIDs": formikValues.warningRecipientIDs,
        "dangerRecipientIDs": formikValues.dangerRecipientIDs,
    }
    return asset;
}