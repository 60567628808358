import NotificationIcon from '@mui/icons-material/Notifications';
import AntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import { Box, Button, Grid, Stack, Switch, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmMessage from '../Generic/ConfirmMessage';
import StatusBackdrop from "../Generic/StatusBackdrop";
import AssetForm from './AssetForm';
import VBTxConfigurationStepper from './VBTxConfiguration/VBTxConfigurationStepper';
import { VibrationAnalysisType } from "./VibrationAnalysisType";
import { fetchAsset, fetchCompanyNodes, fetchUserInfo, fetchVibrationEnums, postAsset } from "./VibrationQueryFunctions";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
}));

const MAX_RPM = 5400;
const MIN_RPM = 600;
export default function AddAsset() {
    const navigate = useNavigate();
    const toast = useRef(null);

    // Consume parameters from URL access
    const { companyID, userID, objectID, viewAll } = useParams();

    const [lowRPMError, setLowRPMError] = useState(false);
    const [highRPMError, setHighRPMError] = useState(false);
    const [lowRPMHelperText, setLowRPMHelperText] = useState("");
    const [highRPMHelperText, setHighRPMHelperText] = useState("");

    const [confirmProps, setConfirmProps] = useState({});

    const [assetName, setAssetName] = useState("");
    const [machineType, setMachineType] = useState(0);
    const [analysisType, setAnalysisType] = useState(5);
    const [rpmType, setRpmType] = useState(0);
    const [rpm, setRpm] = useState(1800);
    const [lowRpm, setLowRpm] = useState(1500);
    const [highRpm, setHighRpm] = useState(3900);
    const [vibrationSamplingPeriod, setVibrationSamplingPeriod] = useState(3600);
    const [accelRange, setAccelRange] = useState(1);
    const [bladeCount, setBladeCount] = useState(0);

    const [enums, setEnums] = useState([]);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [unassignedNodes, setUnassignedNodes] = useState([]);
    const [unassignedNodesList, setUnassignedNodesList] = useState([]);
    const [vbtxAsset, setVBTxAsset] = useState();
    const [primaryRPMErrorText, setPrimaryRPMErrorText] = useState("");
    const [lowRPMErrorText, setLowRPMErrorText] = useState("");
    const [highRPMErrorText, setHighRPMErrorText] = useState("");

    const [p1Nodes, setP1Nodes] = useState([]);
    const [p2Nodes, setP2Nodes] = useState([]);
    const [p3Nodes, setP3Nodes] = useState([]);
    const [p4Nodes, setP4Nodes] = useState([]);


    const [dangerThresholds, setDangerThresholds] = useState([0.3, 0.3, 0.3, 0.3]);
    const [warningThresholds, setWarningThresholds] = useState([0.1, 0.1, 0.1, 0.1]);
    const [dangerRecipientIDs, setDangerRecipientIDs] = useState([[], [], [], []]);
    const [warningRecipientIDs, setWarningRecipientIDs] = useState([[], [], [], []]);
    const [orientationIDs, setOrientationIDs] = useState([30, 30, 30, 30]);
    const [activePosIdx, setActivePosIdx] = useState(1);


    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadedUsers, setIsLoadedUsers] = useState(false);
    const [isLoadedNodes, setIsLoadedNodes] = useState(false);

    const [viewVBTxAsset, setViewVBTxAsset] = useState(true);
    const [vbtxAssetNodes, setVbtxAssetNodes] = useState([]);
    const [vbtxDisabled, setVbtxDisabled] = useState(false);

    // Populate enums for Asset Display
    const { isLoading: enumsLoading, error: enumsError, data: dataE, refetch: refetchEnums } =
        useQuery(["vibrationEnums"], fetchVibrationEnums, {
            fetchPolicy: "network-only",
            onSuccess: (dataE) => {
                setEnums(dataE)
                setIsLoadedEnums(true);
            }
        });

    // Populate users for Alarm Recipient List
    const { isLoading: usersLoading, error: usersError, data: dataU } =
        useQuery(["users", companyID, viewAll], fetchUserInfo, {
            fetchPolicy: "network-only",
            onSuccess: (dataU) => {
                dataU.forEach((user) => {
                    user.Key = "RN" + user.UserID
                    user.DisplayName = user.UserName
                })
                setUsers(dataU)
                setIsLoadedUsers(true);
            }
        });

    // Populate nodes for Asset Display
    const { isLoading: nodesLoading, error: nodesError, data: dataN } =
        useQuery(["nodes", companyID], fetchCompanyNodes, {
            fetchPolicy: "network-only",
            onSuccess: (dataN) => {
                const tempUnassignedNodes = []
                dataN.CompanyNodes.map(({ NodeID, DisplayName, ProductionReadableSN }) => {
                    if (!dataN.AssignedCompanyNodes.some(n => n.NodeID == NodeID)) {
                        tempUnassignedNodes.push({ primary: ProductionReadableSN, secondary: DisplayName, nodeID: NodeID, icon: <AntennaIcon />, menuItems: [] })
                    }
                })
                setUnassignedNodes(tempUnassignedNodes);
                setUnassignedNodesList(tempUnassignedNodes);
                setIsLoadedNodes(true);
            }
        });

    // Post Asset Data to DB
    const doPostAsset =
        useMutation((asset) => postAsset(asset), {
            onSuccess: (result) => {
                const toastMessage = {
                    severity: result.status === 200 ? 'success' : 'warn',
                    summary: result.status === 200 ? 'Success Message' : 'Unexpected Status',
                    detail: result.status === 200 ? 'Successfully created asset' : `Unable create asset. Status: ${result.status} - ${result.statusText}`,
                    life: result.status === 200 ? 15000 : 15000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
                // Introduce a 5-second delay before navigating away
                setTimeout(() => {
                    navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
                }, 5000); // 5000 milliseconds = 5 seconds
            },
            onError: () => {
                const toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to create asset.',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    // Handle "copy node" case
    const { isLoading: objectLoading, error: objectError, data: dataO } =
        useQuery(["users", objectID], fetchAsset, {
            enabled: objectID > 0 && isLoadedUsers,
            onSuccess: (data) => {
                if (data) {
                    setVbtxDisabled(true);
                    let VibrationObject = null;
                    let VibrationObjectNodes = null;
                    if (data.VibrationEditAsset) {
                        VibrationObject = data.VibrationEditAsset.VibrationObject
                        VibrationObjectNodes = []
                        setViewVBTxAsset(false);
                        setUnassignedNodesList(unassignedNodes);
                        convertEnableFlagsFromAPI(VibrationObject.EnabledVibrationChannels)
                        setVibrationSamplingPeriod(VibrationObject.SamplingPeriod);

                    } else if (data.VBTxAssetFormValues) {

                        VibrationObject = data.VibrationEditAssetVBTx.VibrationObject
                        VibrationObjectNodes = data.VibrationEditAssetVBTx.VibrationObjectNodes
                        data.VBTxAssetFormValues.AssetName += ' - CLONE';
                        setVBTxAsset(data.VBTxAssetFormValues);
                        setVbtxAssetNodes(data.VibrationEditAssetVBTx.VibrationObjectNodes)
                        setViewVBTxAsset(true);
                    }
                    setAssetName(VibrationObject.DisplayName + " - CLONE");
                    setMachineType(VibrationObject.MachineTypeID);
                    setAnalysisType(VibrationObject.AnalysisTypeID);
                    setRpmType(VibrationObject.DriveTypeID);
                    setRpm(VibrationObject.FixedRPM);
                    setLowRpm(VibrationObject.LowRPM);
                    setHighRpm(VibrationObject.HighRPM);

                    setAccelRange(VibrationObject.AccelerationRangeID);
                    setBladeCount(VibrationObject.GearMeshValue);
                    
                    setWarningRecipientIDs({ ...warningRecipientIDs })
                    setDangerRecipientIDs({ ...dangerRecipientIDs })
                    setWarningThresholds({ ...warningThresholds })
                    setDangerThresholds({ ...dangerThresholds })
                    setOrientationIDs({ ...orientationIDs })
                }
            }
        });

    // Handle changes in node assignment (to positions)
    const handleUnassignedNodesChange = (event) => {
        event.items.forEach((item) => { item.menuItems = [] })
        setUnassignedNodesList(event.items);
    }

    // Handle Position Selection - to populate modal
    const handleModalOpen = (posIdx) => {
        setActivePosIdx(posIdx);
    }

    // Handle changes in orientation
    const handleOrientationChange = (event, posIdx) => {
        orientationIDs[posIdx] = event.target.value;
        setOrientationIDs({ ...orientationIDs })
    }

    // Handle changes in alarming
    const handleAlarmingChange = (event, changeType, posIdx) => {
        switch (changeType) {
            case "WarningThreshold":
                let newWarningThreshold = event.target.value;
                warningThresholds[posIdx] = (newWarningThreshold > 2) ? 2 : (newWarningThreshold < 0) ? 0 : newWarningThreshold;
                setWarningThresholds({ ...warningThresholds });
                break;
            case "WarningThresholdRecipients":
                warningRecipientIDs[posIdx] = event.target.value;
                setWarningRecipientIDs({ ...warningRecipientIDs });
                break;
            case "DangerThreshold":
                let newDangerThreshold = event.target.value;
                dangerThresholds[posIdx] = (newDangerThreshold > 2) ? 2 : (newDangerThreshold < 0) ? 0 : newDangerThreshold;
                setDangerThresholds({ ...dangerThresholds });
                break;
            case "DangerThresholdRecipients":
                dangerRecipientIDs[posIdx] = event.target.value;
                setDangerRecipientIDs({ ...dangerRecipientIDs });
                break;
        }
    }

    ///* Handle Canceling an Edit operation *///
    const handleCancelButtonClick = () => {
        navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
    }

    ///* Handle Saving Assets *///
    const handleSaveButtonClick = () => {

        const errorTexts = [primaryRPMErrorText, lowRPMErrorText, highRPMErrorText];

        const hasError = errorTexts.some(error => error !== "" || error == null);
        if (hasError) {
            return;
        }

        // Function to create Vibration Object Node from position and nodeID
        const getVibrationObjectNode = (positionID, nodeID) => {
            return ({
                NodeID: nodeID,
                PositionID: positionID + 1, // DB is a 1-based index, JS is a 0-based index
                OrientationID: orientationIDs[positionID],
                OffThreshold: 0.005,
                WarningThreshold: warningThresholds[positionID],
                DangerThreshold: dangerThresholds[positionID],
                OffRecipientIDs: [],
                WarningRecipientIDs: warningRecipientIDs[positionID].filter((ID) => { return ID.startsWith("RN") }).map((x) => { return x.replace("RN", "") }),
                DangerRecipientIDs: dangerRecipientIDs[positionID].filter((ID) => { return ID.startsWith("RN") }).map((x) => { return x.replace("RN", "") }),
                OffGroupIDs: [],
                WarningGroupIDs: warningRecipientIDs[positionID].filter((ID) => { return ID.startsWith("RG") }).map((x) => { return x.replace("RG", "") }),
                DangerGroupIDs: dangerRecipientIDs[positionID].filter((ID) => { return ID.startsWith("RG") }).map((x) => { return x.replace("RG", "") })
            })
        }

        // General Asset Creation
        let asset = null;
        asset = {
            CompanyID: companyID,
            UserID: userID,
            VibrationObject: {
                DisplayName: assetName,
                MachineTypeID: machineType,
                DriveTypeID: rpmType,
                AnalysisTypeID: analysisType,
                GearMeshValue: bladeCount,
                FixedRPM: parseInt(rpm),
                LowRPM: parseInt(lowRpm),
                HighRPM: parseInt(highRpm),
                AccelerationRangeID: accelRange,
                SamplingPeriod: vibrationSamplingPeriod,
                EnabledVibrationChannels: convertEnableFlagsForAPI()
            },
            VibrationObjectNodes: []
        }

        // Assign Nodes to Asset
        if (p1Nodes.length > 0) { asset.VibrationObjectNodes.push(getVibrationObjectNode(0, p1Nodes[0].nodeID)) }
        if (p2Nodes.length > 0) { asset.VibrationObjectNodes.push(getVibrationObjectNode(1, p2Nodes[0].nodeID)) }
        if (p3Nodes.length > 0) { asset.VibrationObjectNodes.push(getVibrationObjectNode(2, p3Nodes[0].nodeID)) }
        if (p4Nodes.length > 0) { asset.VibrationObjectNodes.push(getVibrationObjectNode(3, p4Nodes[0].nodeID)) }

        // Create node in the DB
        doPostAsset.mutateAsync(asset);
    }





    ///* Vibration Analysis Enable Flag Handling *///
    const clearBandFlags = () => {
        return ([{ checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }]);
    }

    const setBasicBandFlags = () => {
        return ([{ checked: true }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }]);
    }

    const setLowFreqBandFlags = () => {
        return ([{ checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: false }, { checked: false }]);
    }

    const setIntermediateBandFlags = () => {
        return ([{ checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }]);
    }

    const clearAnalysisFlags = () => {
        return ([{ checked: false }, { checked: false }, { checked: false }]);
    }

    const setIntermediateAnalysisFlags = () => {
        return ([{ checked: true }, { checked: true }, { checked: true }]);
    }

    const clearAllBandFlags = () => {
        enableFlags.x_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.y_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.z_amplitude.checkboxInfo = clearBandFlags();
        enableFlags.x_frequency.checkboxInfo = clearBandFlags();
        enableFlags.y_frequency.checkboxInfo = clearBandFlags();
        enableFlags.z_frequency.checkboxInfo = clearBandFlags();
        enableFlags.x_analysis.checkboxInfo = clearAnalysisFlags();
        enableFlags.y_analysis.checkboxInfo = clearAnalysisFlags();
        enableFlags.z_analysis.checkboxInfo = clearAnalysisFlags();
    }

    const convertFromBandedCheckboxes = (checkboxInfo) => {
        let hexByte =
            (checkboxInfo[0].checked && 0x01) |
            (checkboxInfo[1].checked && 0x02) |
            (checkboxInfo[2].checked && 0x04) |
            (checkboxInfo[3].checked && 0x08) |
            (checkboxInfo[4].checked && 0x10) |
            (checkboxInfo[5].checked && 0x20) |
            (checkboxInfo[6].checked && 0x40) |
            (checkboxInfo[7].checked && 0x80)

        return {
            OALL: checkboxInfo[0].checked,
            SUB: checkboxInfo[1].checked,
            RPMx1: checkboxInfo[2].checked,
            RPMx2: checkboxInfo[3].checked,
            RPMx3: checkboxInfo[4].checked,
            BRG: checkboxInfo[5].checked,
            HLO: checkboxInfo[6].checked,
            HHI: checkboxInfo[7].checked,
            HexByte: hexByte
        }
    }

    const convertToBandedCheckboxes = (APIflags) => {
        let checkboxInfo = clearBandFlags();
        checkboxInfo[0].checked = APIflags.OALL;
        checkboxInfo[1].checked = APIflags.SUB;
        checkboxInfo[2].checked = APIflags.RPMx1;
        checkboxInfo[3].checked = APIflags.RPMx2;
        checkboxInfo[4].checked = APIflags.RPMx3;
        checkboxInfo[5].checked = APIflags.BRG;
        checkboxInfo[6].checked = APIflags.HLO;
        checkboxInfo[7].checked = APIflags.HHI;

        return checkboxInfo
    }

    const convertFromMiscCheckboxes = (xInfo, yInfo, zInfo) => {
        let hexShort =
            (xInfo[0].checked && 0x0008) |
            (yInfo[0].checked && 0x0010) |
            (zInfo[0].checked && 0x0020) |
            (xInfo[1].checked && 0x0040) |
            (yInfo[1].checked && 0x0080) |
            (zInfo[1].checked && 0x0100) |
            (xInfo[2].checked && 0x0200) |
            (yInfo[2].checked && 0x0400) |
            (zInfo[2].checked && 0x0800)

        return {
            GBF1X: xInfo[0].checked,
            GBF1Y: yInfo[0].checked,
            GBF1Z: zInfo[0].checked,
            GBF2X: xInfo[1].checked,
            GBF2Y: yInfo[1].checked,
            GBF2Z: zInfo[1].checked,
            GBF3X: xInfo[2].checked,
            GBF3Y: yInfo[2].checked,
            GBF3Z: zInfo[2].checked,
            hexShort: hexShort
        }
    }

    const convertToMiscCheckboxes = (APIflags) => {
        let checkboxInfo = { x: clearAnalysisFlags(), y: clearAnalysisFlags(), z: clearAnalysisFlags() }
        checkboxInfo.x[0].checked = APIflags.GBF1X;
        checkboxInfo.y[0].checked = APIflags.GBF1Y;
        checkboxInfo.z[0].checked = APIflags.GBF1Z;
        checkboxInfo.x[1].checked = APIflags.GBF2X;
        checkboxInfo.y[1].checked = APIflags.GBF2Y;
        checkboxInfo.z[1].checked = APIflags.GBF2Z;
        checkboxInfo.x[2].checked = APIflags.GBF3X;
        checkboxInfo.y[2].checked = APIflags.GBF3Y;
        checkboxInfo.z[2].checked = APIflags.GBF3Z;

        return checkboxInfo
    }

    const convertEnableFlagsFromAPI = (enabledVibrationChannels) => {
        let miscCheckboxes = convertToMiscCheckboxes(enabledVibrationChannels.misc)
        enableFlags.x_analysis.checkboxInfo = miscCheckboxes.x
        enableFlags.y_analysis.checkboxInfo = miscCheckboxes.y
        enableFlags.z_analysis.checkboxInfo = miscCheckboxes.z
        enableFlags.x_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.x_amp)
        enableFlags.y_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.y_amp)
        enableFlags.z_amplitude.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.z_amp)
        enableFlags.x_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.x_freq)
        enableFlags.y_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.y_freq)
        enableFlags.z_frequency.checkboxInfo = convertToBandedCheckboxes(enabledVibrationChannels.z_freq)
        setEnableFlags(enableFlags);
    }

    const convertEnableFlagsForAPI = () => {
        let EnabledVibrationFlags = {};
        EnabledVibrationFlags["x_amp"] = convertFromBandedCheckboxes(enableFlags.x_amplitude.checkboxInfo);
        EnabledVibrationFlags["y_amp"] = convertFromBandedCheckboxes(enableFlags.y_amplitude.checkboxInfo);
        EnabledVibrationFlags["z_amp"] = convertFromBandedCheckboxes(enableFlags.z_amplitude.checkboxInfo);
        EnabledVibrationFlags["x_freq"] = convertFromBandedCheckboxes(enableFlags.x_frequency.checkboxInfo);
        EnabledVibrationFlags["y_freq"] = convertFromBandedCheckboxes(enableFlags.y_frequency.checkboxInfo);
        EnabledVibrationFlags["z_freq"] = convertFromBandedCheckboxes(enableFlags.z_frequency.checkboxInfo);
        EnabledVibrationFlags["misc"] = convertFromMiscCheckboxes(enableFlags.x_analysis.checkboxInfo, enableFlags.y_analysis.checkboxInfo, enableFlags.z_analysis.checkboxInfo)
        return EnabledVibrationFlags;
    }

    const handleCheckboxClick = (row, index, checked) => {
        row.checkboxInfo[index].checked = checked;
        setEnableFlags({ ...enableFlags });
    }

    // Initialize enable flags
    const [enableFlags, setEnableFlags] = useState({
        x_amplitude: {
            rowTitle: "X Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_amplitude, index, event.target.checked); }
        },
        y_amplitude: {
            rowTitle: "Y Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_amplitude, index, event.target.checked); }
        },
        z_amplitude: {
            rowTitle: "Z Amplitude", checkboxInfo: setLowFreqBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_amplitude, index, event.target.checked); }
        },
        x_frequency: {
            rowTitle: "X Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_frequency, index, event.target.checked); }
        },
        y_frequency: {
            rowTitle: "Y Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_frequency, index, event.target.checked); }
        },
        z_frequency: {
            rowTitle: "Z Frequency", checkboxInfo: clearBandFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_frequency, index, event.target.checked); }
        },
        x_analysis: {
            rowTitle: "X Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.x_analysis, index, event.target.checked); }
        },
        y_analysis: {
            rowTitle: "Y Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.y_analysis, index, event.target.checked); }
        },
        z_analysis: {
            rowTitle: "Z Gear/Blade Pass", checkboxInfo: clearAnalysisFlags(), handleChange: (event, index) => { handleCheckboxClick(enableFlags.z_analysis, index, event.target.checked); }
        },
    });

    // Handle Changes in Position Node Assignment
    const handleNodesChange = (event, posIdx) => {
        event.items.forEach((item) => {
            item.menuItems =
                [
                    { text: "Alignment", icon: <TextRotationAngledownIcon /> },
                    { text: "Alarming", icon: <NotificationIcon /> }
                ]
        })
        switch (posIdx) {
            case 0: setP1Nodes(event.items); break;
            case 1: setP2Nodes(event.items); break;
            case 2: setP3Nodes(event.items); break;
            case 3: setP4Nodes(event.items); break;
        }
        if (p1Nodes.length == 0 && p2Nodes.length == 0 && p3Nodes.length == 0 && p4Nodes.length == 0) {
            setVbtxDisabled(false);
        }
        else {
            setVbtxDisabled(true);
        }
    }

    // Handle Changes in Machine Type
    const handleMachineTypeChange = (event) => {
        setMachineType(event.target.value);
        if (enums) {
            let warningIPS = enums.MachineTypes[event.target.value].WarningIPS;
            let dangerIPS = enums.MachineTypes[event.target.value].DangerIPS;
            setWarningThresholds([warningIPS, warningIPS, warningIPS, warningIPS]);
            setDangerThresholds([dangerIPS, dangerIPS, dangerIPS, dangerIPS]);
        }
    }

    // Handle Changes in Analysis Type
    const handleAnalysisTypeChange = (event) => {
        setAnalysisType(event.target.value);
        switch (event.target.value) {
            case VibrationAnalysisType.BasicVBTCore:
            case VibrationAnalysisType.BasicVBTLowFreq:
            case VibrationAnalysisType.BasicVBTTemp:
                setRpmType(0)
                break;

            case VibrationAnalysisType.BasicVBTCoreVFD:
            case VibrationAnalysisType.BasicVBTLowFreqVFD:
            case VibrationAnalysisType.BasicVBTTempVFD:
                setRpmType(1)
                break;
        }

        switch (event.target.value) {
            case VibrationAnalysisType.BasicVBTTemp:
            case VibrationAnalysisType.BasicVBTTempVFD:
                setVibrationSamplingPeriod(3600);
                break;
        }

        // Set Band Enable Flags
        switch (event.target.value) {
            case VibrationAnalysisType.BasicVBTCore:
            case VibrationAnalysisType.BasicVBTCoreVFD:
            case VibrationAnalysisType.BasicVBTTemp:
            case VibrationAnalysisType.BasicVBTTempVFD:
                clearAllBandFlags();
                enableFlags.x_amplitude.checkboxInfo = setBasicBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setBasicBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setBasicBandFlags();
                break;

            case VibrationAnalysisType.BasicVBTLowFreq:
            case VibrationAnalysisType.BasicVBTLowFreqVFD:
                clearAllBandFlags();
                enableFlags.x_amplitude.checkboxInfo = setLowFreqBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setLowFreqBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setLowFreqBandFlags();
                break;

            case VibrationAnalysisType.IntermediateVBT:
                enableFlags.x_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.y_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.z_amplitude.checkboxInfo = setIntermediateBandFlags();
                enableFlags.x_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                enableFlags.y_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                enableFlags.z_analysis.checkboxInfo = setIntermediateAnalysisFlags();
                break;

            case VibrationAnalysisType.AdvancedVBT:
                break;
        }
        setEnableFlags({ ...enableFlags });
    }

    // Simple Change Handlers
    const handleNameChange = (event) => { setAssetName(event.target.value); }
    const handleRpmTypeChange = (event) => { setRpmType(event.target.value); }
    const handleSamplingPeriodChange = (event) => { setVibrationSamplingPeriod(event.target.value); }
    const handleAccelRangeChange = (event) => { setAccelRange(event.target.value); }
    const handleBladeCountChange = (event) => { setBladeCount(event.target.value); }
    const handleRpmChange = (event) => {
        setRpm(event.target.value);
        if (event.target.value < MIN_RPM || event.target.value > MAX_RPM) {
            setPrimaryRPMErrorText("Value must be between " + MIN_RPM + " and " + MAX_RPM);
        }
        else {
            setPrimaryRPMErrorText("");
        }
    }
    const handleLowRpmChange = (event) => {
        setLowRpm(event.target.value);
        if (event.target.value < MIN_RPM || event.target.value > MAX_RPM) {
            setLowRPMErrorText("Value must be between " + MIN_RPM + " and " + MAX_RPM);
        } else if (event.target.value > highRpm) {
            setLowRPMErrorText("Lower bound can not be greater than the upper bound");
        }
        else {
            setLowRPMErrorText("");
        }
    }
    const handleHighRpmChange = (event) => {

        setHighRpm(event.target.value);
        if (event.target.value < lowRpm) {
            setLowRPMErrorText("Lower bound can not be greater than the upper bound");
        }
        if (event.target.value < MIN_RPM || event.target.value > MAX_RPM) {
            setHighRPMErrorText("Value must be between " + MIN_RPM + " and " + MAX_RPM);
        }
        else {
            setHighRPMErrorText("");
        }
    }

    const handleViewVBTxChecked = (event) => {
        setViewVBTxAsset(event.target.checked);
    }

    // Page Styling
    const pageStyle = {
        margin: "2%"
    }

    return (
        <Box sx={pageStyle}>
            {(enumsLoading || usersLoading || nodesLoading || objectLoading) && <StatusBackdrop open={(enumsLoading || usersLoading || nodesLoading || objectLoading)} />}
            <Grid container spacing={1}>
                <Grid container item spacing={0} justifyContent={viewVBTxAsset ? "center" : ""}>
                    {/* Back Button Grid Item */}
                    <Grid item xs={2}>
                        <Item><Button fullWidth onClick={handleCancelButtonClick} variant="contained">Back To Dashboard</Button></Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction="row" component="label" alignItems="center" justifyContent="center">
                            <Item><Typography align="right">Legacy</Typography></Item>
                            <Item><Switch checked={viewVBTxAsset} onChange={handleViewVBTxChecked} disabled={vbtxDisabled} /></Item>
                            <Item><Typography align="left">VBTx</Typography></Item>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Toast ref={toast} />
            {isLoadedEnums && isLoadedUsers && isLoadedNodes &&
                (viewVBTxAsset ?
                    <VBTxConfigurationStepper
                    editing={false}
                    vbtxAsset={vbtxAsset}
                    vbtxAssetNodes={vbtxAssetNodes}
                    users={users}
                    userGroups={userGroups}
                    setConfirmProps={setConfirmProps}
                    /> :
                    <AssetForm
                        enums={enums}
                        users={users}
                        userGroups={userGroups}

                        assetName={assetName}
                        machineType={machineType}
                        analysisType={analysisType}
                        rpmType={rpmType}
                        rpm={rpm}
                        lowRpm={lowRpm}
                        lowRPMError={lowRPMError}
                        lowRPMHelperText={lowRPMHelperText}
                        highRpm={highRpm}
                        highRPMError={highRPMError}
                        highRPMHelperText={highRPMHelperText}
                        vibrationSamplingPeriod={vibrationSamplingPeriod}
                        accelRange={accelRange}
                        bladeCount={bladeCount}
                        unassignedNodes={unassignedNodesList}
                        p1Nodes={p1Nodes}
                        p2Nodes={p2Nodes}
                        p3Nodes={p3Nodes}
                        p4Nodes={p4Nodes}

                        enableFlags={enableFlags}

                        handleNameChange={handleNameChange}
                        handleMachineTypeChange={handleMachineTypeChange}
                        handleAnalysisTypeChange={handleAnalysisTypeChange}
                        handleBladeCountChange={handleBladeCountChange}
                        handleRpmTypeChange={handleRpmTypeChange}
                        handleRpmChange={handleRpmChange}
                        handleLowRpmChange={handleLowRpmChange}
                        handleHighRpmChange={handleHighRpmChange}
                        handleSamplingPeriodChange={handleSamplingPeriodChange}
                        handleAccelRangeChange={handleAccelRangeChange}

                        handleUnassignedNodesChange={handleUnassignedNodesChange}
                        handleP1NodesChange={handleNodesChange}
                        handleP2NodesChange={handleNodesChange}
                        handleP3NodesChange={handleNodesChange}
                        handleP4NodesChange={handleNodesChange}

                        button1Name="Save"
                        button2Name="Cancel"
                        handleButton1Click={handleSaveButtonClick}
                        handleButton2Click={handleCancelButtonClick}
                        handleBackButtonClick={handleCancelButtonClick}

                        activePosIdx={activePosIdx}
                        dangerThresholds={dangerThresholds}
                        dangerRecipientIDs={dangerRecipientIDs}
                        warningThresholds={warningThresholds}
                        warningRecipientIDs={warningRecipientIDs}
                        handleAlarmingChange={handleAlarmingChange}

                        primaryRPMErrorText={primaryRPMErrorText}
                        lowRPMErrorText={lowRPMErrorText}
                        highRPMErrorText={highRPMErrorText}

                        orientationIDs={orientationIDs}
                        handleOrientationChange={handleOrientationChange}

                        handleModalOpen={handleModalOpen}
                    />)
            }
            <ConfirmMessage {...confirmProps} />
        </Box>
    )
}