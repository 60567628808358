import React from 'react';
import { Grid, Button, Stack } from "@mui/material";
import AlarmThresholdField from "./AlarmThresholdField";


export default function AlarmThresholdModalContent(props) {

    return (
        <Stack sx={{width: '100%'} }>
            <AlarmThresholdField
                value={props.dangerThreshold}
                onChange={props.handleAlarmingChange}
                thresholdID="DangerThreshold"
                key={"danger"}
                name="Danger Alarm"
                color='#a30f0f'
            />
            <AlarmThresholdField
                value={props.warningThreshold}
                onChange={props.handleAlarmingChange}
                thresholdID="WarningThreshold"
                key={"warning"}
                name="Warning Alarm"
                color='#f2cb2e'
            />
            <br />
            <Grid container spacing={2} columns={12}>
                <Grid item xs={4}>
                    <Button fullWidth onClick={props.closeModals} variant="contained">Save</Button>
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Button fullWidth onClick={props.closeModals} variant="contained">Cancel</Button>
                </Grid>
            </Grid>
        </Stack>
    )
}