import React, { useEffect } from 'react';
import { Paper, Modal, Typography} from '@mui/material';

export default function VibrationRedirectModal({ open, setOpen, userID, companyID, vibrationObjectID, viewAll }) {

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false);
                if (vibrationObjectID == 0) {
                    window.open(`/Vibration/AddAsset/${companyID}/${userID}/${viewAll}`, '_blank').focus();
                } else {
                    window.open(`/Vibration/EditAsset/${companyID}/${userID}/${vibrationObjectID}/${viewAll}`, '_blank').focus();
                }
            }, 4000)
        }
    }, [open])

    return (
        <Modal
            open={open}
        >
            <Paper className="modal-paper">
                {vibrationObjectID == 0 ? (<Typography variant="h6" sx={{py: 4}}> Vibration Configuration is done on the Vibration Analysis Page.
                    This Sensor is not on an asset so a new asset will be created.
                    Opening a new tab to Create an Asset on the Vibration Analysis Page...</Typography>) :
                    (<Typography variant="h6" sx={{ py: 4 }}> Vibration Configuration is done on the Vibration Analysis Page.
                        This Sensor is already on an asset.
                        Opening a new tab to the Edit Asset Page for this asset on the Vibration Analysis Page.</Typography>) }
            </Paper>
        </Modal>
    )
}